import React from 'react';
import ReactDOM from 'react-dom';

import { EXPERIMENT_POST_PRINT } from '@wix/communities-blog-experiments';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';

import { connect } from '../runtime-context';
import { isEditor, isPreview } from '../../store/basic-params/basic-params-selectors';

import styles from './print-wrapper.scss';

const PRINT_CONTAINER_HACK_ID = 'wix-blog-print-container';
const printCss = `
  @media print {
    body > *:not(#${PRINT_CONTAINER_HACK_ID}) {
      display: none !important;
    }
  }`
  .replace(/\s+/g, ' ')
  .trim();

class PrintWrapper extends React.Component {
  state = {
    portalContainer: null,
  };

  printStyleElement = null;
  previousOnBeforePrint = () => {};

  componentDidMount() {
    const canPrint = (!this.props.nonPrintEnv || this.props.isPostPrintEnabled) && typeof window !== 'undefined';
    if (canPrint) {
      // This is for sled tests
      if (window.matchMedia('print').matches) {
        this.createPrintContainer();
      }
      if (window.onbeforeprint) {
        this.previousOnBeforePrint = window.onbeforeprint;
      }
      window.onbeforeprint = () => {
        this.createPrintContainer();
      };
    }
  }

  createPrintContainer = () => {
    if (!this.state.portalContainer) {
      // create print container as <body> child
      const portalContainer = document.createElement('div');
      portalContainer.setAttribute('id', PRINT_CONTAINER_HACK_ID);
      portalContainer.classList.add(styles.printContainer);
      document.body.insertBefore(portalContainer, document.body.firstChild);
      this.setState({ portalContainer });

      // add print styles and hide everything else on the page
      this.printStyleElement = document.createElement('style');
      this.printStyleElement.type = 'text/css';
      this.printStyleElement.innerText = printCss;
      document.head.appendChild(this.printStyleElement);
    }
  };

  componentWillUnmount() {
    if (this.printStyleElement) {
      document.head.removeChild(this.printStyleElement);
      this.printStyleElement = null;
    }
    if (this.state.portalContainer) {
      document.body.removeChild(this.state.portalContainer);
      this.setState({ portalContainer: null });
    }
    if (this.previousOnBeforePrint) {
      window.onbeforeprint = this.previousOnBeforePrint;
      this.previousOnBeforePrint = () => {};
    }
  }

  render() {
    const { children } = this.props;

    const portal = this.state.portalContainer
      ? ReactDOM.createPortal(<div className={styles.printContent}>{children}</div>, this.state.portalContainer)
      : null;

    return (
      <>
        {children}
        {portal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nonPrintEnv: isEditor(state) || isPreview(state),
    isPostPrintEnabled: isExperimentEnabled(state, EXPERIMENT_POST_PRINT),
  };
};

export default connect(mapStateToProps)(PrintWrapper);
